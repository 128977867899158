const CalendarEvent = ({ event }) => {
  const { title, start, end, notes } = event;

  if (!event) return null; // Handle case where event is undefined or null
  // console.log('event', event);

  return (
    <div>
      <strong>{title}</strong>
      {/* <p>
        <strong>Program: </strong>" {notes}"
      </p> */}
    </div>
  );
};
export default CalendarEvent;
