import React from "react";
function PdfViewer() {
  return (
    <div>
      <embed
        width="100%"
        height="1100vh"
        src="https://drive.google.com/file/d/1zNdF1YUwcyx4VR3XGscDalGg2Jp9Syk4/preview?usp=sharing"
      />
    </div>
  );
}

export default PdfViewer;
