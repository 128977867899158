import { useDispatch } from 'react-redux';
import { eventClearActive, openModal } from '../../actions/event';

import PlusIcon from './icons/PlusIcon';

const AddNewBtn = () => {
  const dispatch = useDispatch();

  const handleClickNew = () => {
    dispatch(eventClearActive());
    dispatch(openModal());
  };

  return (
    <button className='btn btn-primary btn--floating' onClick={handleClickNew}>
      <PlusIcon />
    </button>
  );
};
export default AddNewBtn;
