import React, { Fragment } from "react";

const ManageUsers = () => {
  return (
    <Fragment>
      <section className="container">
        <h1 className="large text-primary">Manage Users</h1>
        <p className="lead">
          <i className="fas fa-user" />
          Load users details in the grid or table to manage them.
        </p>
      </section>
    </Fragment>
  );
};

export default ManageUsers;
