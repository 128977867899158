import React, { Fragment } from 'react';

import DonateForm from './DonateForm';
import FacebookPlugin from './FacebookPlugin';
import BlogBox from './BlogBox';

import './Sidebar.css';

const Sidebar = () => {
  return (
    <Fragment>
      <div className='container-fluid'>
        <DonateForm />
        <FacebookPlugin />
        <BlogBox />
      </div>
    </Fragment>
  );
};

export default Sidebar;
