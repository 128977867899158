import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import BrandName from "../brandname/BrandName";

import "./Navbar.css";
import GuestLinks from "./GuestLinks";
import AdminLinks from "./AdminLinks";
import AuthLinks from "./AuthLinks";
import RoleList from "../shared/enums/RoleList";
import { toggleMenu } from "../../actions/togglemenu";

const Navbar = ({
  isAuthenticated,
  roles,
  loading,
  logout,
  menuOpen,
  toggleMenu,
}) => {
  const getLinks = (isAuthenticated, roles, logout, menuOpen) => {
    if (!isAuthenticated) {
      return <GuestLinks />;
    }

    if (roles && roles.Admin === RoleList.ADMIN) {
      return <AdminLinks logout={logout} />;
    }

    return <AuthLinks logout={logout} />;
  };

  return (
    <nav className="bg-primary">
      <h1>
        <Link to="/">
          <BrandName />
        </Link>
      </h1>
      <div className="menu" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      {!loading && (
        <Fragment>
          {getLinks(isAuthenticated, roles, logout, menuOpen)}
        </Fragment>
      )}
    </nav>
  );
};

Navbar.propTypes = {
  isAuthenticated: PropTypes.bool,
  roles: PropTypes.object,
  menuOpen: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const roles = state.auth.user ? state.auth.user.roles : {};
  const menuOpen = state.toggleMenu.menuOpen;
  console.log("abc", menuOpen);
  return {
    isAuthenticated: state.auth.isAuthenticated || false,
    roles,
    loading: state.auth.loading,
    menuOpen,
  };
};

export default connect(mapStateToProps, { logout, toggleMenu })(Navbar);
