import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers'; // Assuming you have combined your reducers

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(), // This includes redux-thunk by default
  //   devTools: process.env.NODE_ENV ! = 'production', // Enable DevTools only in development
});

export default store;
