import React, { Fragment } from "react";
import "./About.css";
const About = () => {
  return (
    <Fragment>
      <div className="about-us text-dark">
        <h3 className="text-center">Chicagoland Tharu Society</h3>
        <p className=" py">
          Established in 2005, the Chicagoland Tharu Society is a non-profit
          organization dedicated to celebrating and preserving the rich cultural
          heritage of the Tharu community in the Greater Chicago and Chicagoland
          area.
        </p>
      </div>

      <div className="wrapper text-dark">
        <div className="mission">
          <div>
            <h3 className=" py">Our Mission</h3>
            <ul>
              <li>
                <p className="">
                  Preserve Tharu Heritage: Safeguard and promote the unique
                  cultural traditions, languages, and heritage of the Tharu
                  people.
                </p>
              </li>
              <li>
                <p className=" py">
                  Support Integration: Assist Tharu community members in their
                  integration into American society while maintaining their
                  cultural identity.
                </p>
              </li>
              <li>
                <p className=" py">
                  Promote Education: Encourage educational pursuits and provide
                  resources to support academic and professional development.
                </p>
              </li>
              <li>
                <p className=" py">
                  Cultural Exchange: Facilitate cultural exchange programs to
                  promote understanding and appreciation of Tharu culture among
                  the broader community.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="vision">
          <div>
            <h3 className=" py">Our Vission</h3>
            <p className="">
              We envision a vibrant and cohesive Nepali Indigenous Tharu
              community in the Chicagoland area, where members can thrive while
              preserving their cultural roots. Our goal is to be a beacon of
              Tharu culture, offering a platform for cultural expression and
              community engagement.
            </p>
          </div>
        </div>
        <div className="activities">
          <div>
            <h3 className=" py">Our Activities</h3>
            <ul>
              <li>
                <p className=" py">
                  Cultural Celebrations: We host festivals, cultural
                  performances, and traditional celebrations to keep Tharu
                  customs and traditions alive.
                </p>
              </li>
              <li>
                <p className=" py">
                  Educational Programs: Our society offers workshops, language
                  classes, and tutoring services to support the educational
                  needs of our community members.
                </p>
              </li>
              <li>
                <p className=" py">
                  Community Support: We provide assistance with immigration
                  processes, job placements, and other essential services to
                  help our members navigate life in the U.S.
                </p>
              </li>
              <li>
                <p className=" py">
                  Youth Engagement: We engage the younger generation through
                  sports, arts, and leadership programs to ensure they stay
                  connected to their heritage while developing essential life
                  skills.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="help-contact">
          <div>
            <h3 className=" py">How can you help us?</h3>
            <h3 className=" py">
              We warmly welcome anyone interested in supporting our mission and
              becoming a part of our community. Here are some ways you can get
              involved:
            </h3>

            <ul>
              <li>
                <p className=" py">
                  Membership: Join the Chicagoland Tharu Society and participate
                  in our events and programs.
                </p>
              </li>
              <li>
                <p className=" py">
                  Volunteering: Contribute your time and skills to our various
                  initiatives and help us make a difference.
                </p>
              </li>
              <li>
                <p className=" py">
                  Donations: Support our mission and programs through donations,
                  ensuring the sustainability of our efforts.
                </p>
              </li>
            </ul>
            <div>
              <h3 className="">Contact Us</h3>
              <ul>
                <li className="">Email: usatharus@gmail.com</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
