import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  eventClearActive,
  addEvent,
  updateEvent,
  removeError,
  setError,
  closeModal,
  getEvents,
} from '../../actions/event';
import {
  selectCalendarActiveEvent,
  selectCalendarModalOpen,
  selectCalendarMsgError,
} from './Selectors';
import Alert from './Alert';
// import Alert from '../../components/shared/Alert';

Modal.setAppElement('#root');

const nowInitial = moment().minutes(0).seconds(0).add(1, 'hour');
const nowEnd = nowInitial.clone().add(1, 'hour');

const initEvent = {
  title: '',
  notes: '',
  start: nowInitial.toDate(),
  end: nowEnd.toDate(),
};

const CalendarModal = () => {
  const dispatch = useDispatch();

  const activeEvent = useSelector(selectCalendarActiveEvent);
  const modalOpen = useSelector(selectCalendarModalOpen);
  const msgError = useSelector(selectCalendarMsgError);

  const [formValues, setFormValues] = useState(initEvent);
  const { notes, title, start, end } = formValues;

  useEffect(() => {
    if (activeEvent) {
      setFormValues(activeEvent);
    } else {
      setFormValues(initEvent);
    }
  }, [activeEvent, modalOpen]);

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const offModal = () => {
    if (modalOpen) {
      dispatch(eventClearActive());
      dispatch(closeModal());
    }
  };

  const handleStartDateChange = (e) => {
    setFormValues({
      ...formValues,
      start: new Date(e.target.value),
    });
  };

  const handleEndDateChange = (e) => {
    setFormValues({
      ...formValues,
      end: new Date(e.target.value),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) return;

    const event = {
      ...formValues,
      start: new Date(formValues.start),
      end: new Date(formValues.end),
    };

    if (activeEvent && activeEvent._id) {
      // Update

      dispatch(updateEvent(event));
    } else {
      // Create new
      dispatch(addEvent(event));
    }

    dispatch(closeModal());
  };

  const onCancel = () => {
    dispatch(closeModal());
  };
  const isFormValid = () => {
    if (title.trim().length === 0) {
      dispatch(setError('Title is required'));
      return false;
    } else if (title.trim().length > 32) {
      dispatch(setError('Title length must be max 32 characters'));
      return false;
    }
    // else if (moment(start).isSameOrAfter(moment(end))) {
    //   dispatch(setError('End date can be same day or after start date'));
    //   return false;
    // }
    else if (notes && notes.trim().length > 128) {
      dispatch(setError('Notes length must be max 128 characters'));
      return false;
    }
    dispatch(removeError());
    return true;
  };

  const formatDateTimeLocal = (date) => {
    return moment(date).format('YYYY-MM-DDTHH:mm');
  };

  // Refresh events after modal is closed
  useEffect(() => {
    if (!modalOpen) {
      dispatch(getEvents());
    }
  }, [modalOpen, dispatch]);

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={offModal}
      closeTimeoutMS={200}
      contentLabel='Event modal'
      className='modal'
      overlayClassName='modal__background'
    >
      <section>
        <h1 className='text-primary'>
          {activeEvent ? 'Edit event' : 'New event'}
        </h1>

        <form className='form' onSubmit={handleSubmit}>
          {msgError && <Alert type='error' description={msgError} />}
          <div className='form-group'>
            <input
              type='text'
              placeholder='Title'
              name='title'
              value={title}
              onChange={handleInputChange}
            />
          </div>
          <div className='form-group'>
            <small className='form-text'>Start Date</small>
            <input
              type='datetime-local'
              name='start'
              value={formatDateTimeLocal(start)}
              onChange={handleStartDateChange}
            />
          </div>
          <div className='form-group'>
            <small className='form-text'>End Date</small>
            <input
              type='datetime-local'
              name='end'
              value={formatDateTimeLocal(end)}
              onChange={handleEndDateChange}
            />
          </div>
          <div className='form-group'>
            <textarea
              placeholder='Notes'
              name='notes'
              value={notes}
              onChange={handleInputChange}
            />
          </div>
          <button className='btn btn-primary' type='submit'>
            Save
          </button>
          <button className='btn btn-secondary' onClick={onCancel}>
            Cancel
          </button>
        </form>
        {/* <span>
          <Alert />
        </span> */}
      </section>
    </Modal>
  );
};
export default CalendarModal;
