import styles from "./LocalOrganization.module.css";
import tafalogo from "../../assests/images/tafalogo.jpg";
import cnfslogo from "../../assests/images/cnfslogo.jpg";
import nalogo from "../../assests/images/nalogo.png";
import cntslogo from "../../assests/images/cntslogo.png";
import nrnalogo from "../../assests/images/nrnalogo.jpg";

function LocalOrganization() {
  return (
    
    <div className={styles.flexContainer}>
      <div className={styles.gridItem}>
        <span className={styles.spanOrgs}>
          <a
            href="https://tharus.org/"
            target="_blank"
            className={styles.localOrg}
          >
            <img src={tafalogo} alt="TFA" className={styles.orgLogo} />
            <p className={styles.orgName}>TFA</p>
          </a>
        </span>
      </div>
      <div className={styles.gridItem}>
        <span className={styles.spanOrgs}>
          <a
            href="https://new.cnfsusa.org/"
            target="_blank"
            className={styles.localOrg}
          >
            <img src={cnfslogo} alt="CNFS" className={styles.orgLogo} />
            <p className={styles.orgName}>CNFS</p>
          </a>
        </span>
      </div>
      <div className={styles.gridItem}>
        <span className={styles.spanOrgs}>
          <a
            href="https://aid.kusaengage.com/"
            target="_blank"
            className={styles.localOrg}
          >
            <img src={nalogo} alt="NA" className={styles.orgLogo} />
            <p className={styles.orgName}>NA</p>
          </a>
        </span>
      </div>
      <div className={styles.gridItem}>
        <span className={styles.spanOrgs}>
          <a
            href="https://cntsnepal.org//"
            target="_blank"
            className={styles.localOrg}
          >
            <img src={cntslogo} alt="CNTS" className={styles.orgLogo} />
            <p className={styles.orgName}>CNTS</p>
          </a>
        </span>
      </div>
      <div className={styles.gridItem}>
        <span className={styles.spanOrgs}>
          <a
            href="https://www.nrnusa.org/"
            target="_blank"
            className={styles.localOrg}
          >
            <img src={nrnalogo} alt="NRNA" className={styles.orgLogo} />
            <p className={styles.orgName}>NRNA</p>
          </a>
        </span>
      </div>
    </div>



  );
}

export default LocalOrganization;
