import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import formatDate from "../../utils/formatDate";

const calculateAge = (dob) => {
  const birthDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  // console.log("Remaining Month", monthDifference);
  const dayDifference = today.getDate() - birthDate.getDate();
  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }
  return age;
};

const calculateRemainingDayofBirthDay = (dob) => {
  const birthDay = new Date(dob);
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const monthDifference = birthDay.getMonth() - today.getMonth();
  if (monthDifference === 0) {
    return birthDay.getDate() - today.getDate() + 1;
  }

  return lastDayOfMonth.getDate() - today.getDate() + birthDay.getDate() + 1;
};

const BirthdayItem = ({ profile }) => {
  if (!profile || !profile.user) {
    return;
  }

  const {
    user: { _id, name, avatar },
    dob,
  } = profile;

  const age = calculateAge(dob);
  const remainingDayofBirthDay = calculateRemainingDayofBirthDay(dob);

  return (
    <div className="birthday-item bg-light">
      <Link to={`/profile/${_id}`}>
        <img src={avatar} alt="" className="round-img" />
        <div>
          <h6 className="text-center">{name}</h6>
        </div>
      </Link>
      {remainingDayofBirthDay === 0 && (
        <div>
          <h5 className="text-primary">Happy Birthday! </h5>
        </div>
      )}
      {remainingDayofBirthDay > 0 && (
        <div>
          <h6>After {remainingDayofBirthDay} days</h6>
        </div>
      )}
    </div>
  );
};

BirthdayItem.propTypes = {
  profile: PropTypes.shape({
    user: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
    }),
    dob: PropTypes.string.isRequired,
  }).isRequired,
};

export default BirthdayItem;
