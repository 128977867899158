import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { deleteEvent } from '../../actions/event';
import { selectCalendarActiveEvent } from './Selectors';
import DeleteIcon from './icons/DeleteIcon';

const DeleteBtn = () => {
  const dispatch = useDispatch();
  const activeEvent = useSelector(selectCalendarActiveEvent);

  const handleClickDelete = () => {
    if (!activeEvent) return;

    Swal.fire({
      title: 'Delete event',
      text: 'Do you want to delete the selected event?',
      icon: 'warning',
      confirmButtonText: 'Yes, delete!',
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        dispatch(deleteEvent(activeEvent._id));
      }
    });
  };

  return (
    <button
      className='btn btn-danger btn--floating btn--floating-left'
      onClick={handleClickDelete}
    >
      <DeleteIcon />
    </button>
  );
};

export default DeleteBtn;
