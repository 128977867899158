import React from 'react';
import ctsLogo from '../../assests/images/ctslogo.jpeg';
import './BrandName.css';

const BrandName = () => {
  return (
    <div className='brand'>
      <img src={ctsLogo} alt='CTS Logo' className='logo ' />
      <p className='title'>Chicagoland Tharu Society</p>
    </div>
  );
};

export default BrandName;
