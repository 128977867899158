import React, { Fragment } from 'react';

const DonateForm = () => (
  <Fragment>
    <div className='head'>
      <p className='p'>Please click here for Donate</p>
    </div>
    <div className='donate-container'>
      <form action='https://www.paypal.com/donate' method='post' target='_top'>
        <input
          type='hidden'
          name='hosted_button_id'
          defaultValue='Q4V8NCF7P94L6'
        />
        <input
          type='image'
          src='https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif'
          name='submit'
          title='PayPal - The safer, easier way to pay online!'
          alt='Donate with PayPal button'
        />
        <img
          alt=''
          src='https://www.paypal.com/en_US/i/scr/pixel.gif'
          width={1}
          height={1}
        />
      </form>
    </div>
  </Fragment>
);

export default DonateForm;
