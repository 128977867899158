import { useEffect, useState, Fragment } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import CalendarEvent from "./CalendarEvent";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.css";

import CalendarModal from "./CalendarModal";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../actions/event";
import {
  eventClearActive,
  eventSetActive,
  getEvents,
} from "../../actions/event";
import {
  selectCalendarEvents,
  selectCalendarActiveEvent,
  selectCalendarModalOpen,
  selectUser,
} from "./Selectors";
import AddNewBtn from "./AddNewBtn";
import DeleteBtn from "./DeleteBtn";
import RoleList from "../shared/enums/RoleList";

const localizer = momentLocalizer(moment);

const CalendarScreen = () => {
  const dispatch = useDispatch();
  const events = useSelector(selectCalendarEvents);
  const activeEvent = useSelector(selectCalendarActiveEvent);
  const modalOpen = useSelector(selectCalendarModalOpen);
  const user = useSelector(selectUser);
  const adminRole = user?.roles?.Admin === RoleList.ADMIN;
  // const [lastView, setLastView] = useState(
  //   localStorage.getItem('lastView') || 'month'
  // );

  useEffect(() => {
    dispatch(getEvents());
  }, [dispatch]);

  const onDoubleClick = (e) => {
    if (adminRole) {
      dispatch(openModal());
    }
  };

  const onSelect = (e) => {
    if (adminRole) {
      dispatch(eventSetActive(e));
    }
  };

  const onViewChange = (e) => {
    // setLastView(e);
    // localStorage.setItem('lastView', e);
  };

  const onSelectSlot = (e) => {
    if (adminRole) {
      activeEvent && dispatch(eventClearActive());
      if (e.action === "select" || e.action === "doubleClick") {
        dispatch(
          eventSetActive({
            title: "",
            notes: "",
            start: e.start,
            end: e.end,
          })
        );
        dispatch(openModal());
      }
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = "#b64fc8"; // default color
    const now = moment();
    if (moment(end).isBefore(now, "day")) {
      backgroundColor = "#FF6347"; // past event color
    } else if (moment(start).isSame(now, "day")) {
      backgroundColor = "#32CD32"; // present event color
    } else if (moment(start).isAfter(now, "day")) {
      backgroundColor = "#1E90FF"; //'#b64fc8'; // future event color
    }

    const style = {
      backgroundColor,
      opacity: 0.8,
      display: "block",
      color: "white",
    };

    return { style };
  };

  // Specify only the month view in the views prop
  const views = ["month"];

  return (
    <Fragment>
      <div className="calendar">
        <div className="calendar__container">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={eventStyleGetter}
            onDoubleClickEvent={onDoubleClick}
            onSelectEvent={onSelect}
            onView={onViewChange}
            onSelectSlot={onSelectSlot}
            selectable={true}
            // view={views}
            defaultView="month"
            components={{ event: CalendarEvent }}
          />
        </div>
        {adminRole && activeEvent && !modalOpen && <DeleteBtn />}
        {adminRole && <AddNewBtn />}
        {adminRole && <CalendarModal />}
      </div>
    </Fragment>
  );
};

export default CalendarScreen;
