import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import extractYear from "../../utils/extractTimeAndYear";

const MemberItem = ({ profile }) => {
  if (!profile || !profile.user) {
    return;
  }

  const { user, membership } = profile;
  const { from, to, current, title, active } = membership;

  const fromDate = typeof from === "string" ? new Date(from) : from;
  const toDate = typeof to === "string" ? new Date(to) : to;

  const currentDate = new Date();

  const { year: startYear } = fromDate
    ? extractYear(fromDate.toISOString())
    : { year: "N/A" };
  const { year: endYear } = toDate
    ? extractYear(toDate.toISOString())
    : { year: currentDate.getFullYear() };

  return (
    <div className="member-item bg-light">
      <Link to={`/profile/${user._id}`}>
        <img src={user.avatar} alt="" className="round-img" />
        <div>
          <h6 className="text-center">{user.name}</h6>
        </div>
      </Link>
      <div>
        <h6>{title}</h6>
      </div>
      <div>
        <h6>
          {startYear} - {current ? currentDate.getFullYear() : endYear}
        </h6>
      </div>
      <div>
        <h6 className={active ? "text-success p-1" : "text-danger p-1"}>
          {console.log("Active ", active)}
          {active ? "Active" : "Inactive"}
        </h6>
      </div>
    </div>
  );
};

MemberItem.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default MemberItem;
