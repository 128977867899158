import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteMembership } from '../../actions/profile';
import formatDate from '../../utils/formatDate';

const Membership = ({ membership, deleteMembership }) => {
  const memberships = membership.map((memb) => (
    <tr key={memb._id}>
      <td>{memb.title}</td>
      <td className='hide-sm'>{memb.category}</td>
      <td className='hide-sm'>{memb.type}</td>
      <td>
        {memb.from && formatDate(memb.from)} -{' '}
        {memb.to ? memb.to && formatDate(memb.to) : 'Current'}
      </td>
      <td className='hide-sm'>
        {}
        <span className={memb.active ? 'text-success p-1' : 'text-danger p-1'}>
          {memb.active ? 'Active' : 'Inactive'}
        </span>
      </td>
      <td>
        <button
          onClick={() => deleteMembership(memb._id)}
          className='btn btn-danger'
        >
          Delete
        </button>
      </td>
    </tr>
  ));

  return (
    <Fragment>
      <h2 className='my-2'>Membership Details</h2>
      <table className='table'>
        <thead>
          <tr>
            <th>Title</th>
            <th className='hide-sm'>Category</th>
            <th className='hide-sm'> Type</th>
            <th className='hide-sm'>Duration</th>
            <th className='hide-sm'>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>{memberships}</tbody>
      </table>
    </Fragment>
  );
};

Membership.propTypes = {
  membership: PropTypes.array.isRequired,
  deleteMembership: PropTypes.func.isRequired,
};

export default connect(null, { deleteMembership })(Membership);
