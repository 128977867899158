import React, { Fragment } from "react";

import Navbar from "../navbar/Navbar";

import "./Header.css";
const Header = () => {
  return (
    <Fragment>
      <Navbar />
    </Fragment>
  );
};

export default Header;
