import React, { Fragment } from "react";
import PdfViewer from "./PdfViewer";
const Bylaws = () => {
  return (
    <Fragment>
      <PdfViewer />
    </Fragment>
  );
};

export default Bylaws;
