const isDateValid = (from, to) => {
  // Helper function to parse date strings
  const parseDate = (dateString) => {
    // Split the input dateString by the '/' character
    const parts = dateString.split('/');

    // Check if the parts array has exactly 3 elements (assuming a MM/DD/YYYY format)
    if (parts.length === 3) {
      // If the format is MM/DD/YYYY, create a new Date object with the following parameters:
      // - parts[2]: the year (converted to a number using parseInt)
      // - parts[0]: the month (converted to a number using parseInt, and subtracting 1 to get the zero-based month index)
      // - parts[1]: the day (converted to a number using parseInt)
      return new Date(
        parseInt(parts[2], 10),
        parseInt(parts[0], 10) - 1,
        parseInt(parts[1], 10)
      );
    } else {
      // If the format is not MM/DD/YYYY, use the default Date constructor to parse the dateString
      return new Date(dateString);
    }
  };

  // Parse the input dates
  const fromDate = parseDate(from);
  const toDate = to ? parseDate(to) : null;

  // Check if the from date is a valid date
  if (isNaN(fromDate.getTime())) {
    return false;
  }

  // If the to date is not provided, consider it valid
  if (toDate === null) {
    return true;
  }

  // Check if the to date is greater than or equal to the from date
  return toDate.getTime() >= fromDate.getTime();
};

export default isDateValid;
