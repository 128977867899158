import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { toggleMenu } from "../../actions/togglemenu";
import propsTypes from "prop-types";
const AdminLinks = ({ logout, menuOpen, toggleMenu }) => {
  return (
    <Fragment>
      <ul className={menuOpen ? "open" : ""}>
        <li>
          <NavLink to="/" onClick={toggleMenu}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" onClick={toggleMenu}>
            About
          </NavLink>
        </li>
        <li>
          <NavLink to="/bylaws" onClick={toggleMenu}>
            Bylaws
          </NavLink>
        </li>
        <li>
          <NavLink to="/calendar" onClick={toggleMenu}>
            Calendar
          </NavLink>
        </li>
        <li>
          <NavLink to="/profiles" onClick={toggleMenu}>
            Members
          </NavLink>
        </li>
        <li>
          <NavLink to="/posts" onClick={toggleMenu}>
            Posts
          </NavLink>
        </li>
        <li>
          <NavLink to="/dashboard" onClick={toggleMenu}>
            <i className="fas fa-user"></i>{" "}
            <span /*className='hide-sm'*/>Profile</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/users" onClick={toggleMenu}>
            <i className="fas fa-user"></i>{" "}
            <span /*className='hide-sm'*/>Manage Users</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/login" onClick={logout}>
            <i className="fas fa-sign-out-alt"></i>{" "}
            <span /*className='hide-sm'*/>Logout</span>
          </NavLink>
        </li>
      </ul>
    </Fragment>
  );
};

AdminLinks.propsTypes = {
  toggleMenu: propsTypes.func.isRequired,
  menuOpen: propsTypes.object,
};

const mapStateToProps = (state) => {
  const menuOpen = state.toggleMenu.menuOpen;
  return {
    menuOpen,
  };
};

export default connect(mapStateToProps, { toggleMenu })(AdminLinks);
