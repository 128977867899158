import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import formatDate from '../../utils/formatDate';

const ProfileItem = ({ profile }) => {
  if (!profile || !profile.user) {
    return null;
  }

  const { _id, name, avatar } = profile.user;
  const { position, company, location, skills, membership = [] } = profile;

  const sortedMembership = [...membership].sort((a, b) => {
    if (a.current && b.current) return 0;
    if (a.current) return -1;
    if (b.current) return 1;
    return new Date(b.to).getTime() - new Date(a.to).getTime();
  });

  const singleMembership =
    sortedMembership.length > 0 ? sortedMembership[0] : null;
  const { title, category, type, from, current, to, active } =
    singleMembership || {};

  // Formatting the date range
  const fromFormatted = from && formatDate(from);
  const toFormatted = current ? 'Current' : to && formatDate(to);
  const dateRange =
    fromFormatted && toFormatted && `${fromFormatted} - ${toFormatted}`;

  return (
    <div className='profile bg-light'>
      <img src={avatar} alt='' className='round-img' />
      <div>
        <h2>{name}</h2>
        <p>
          {/* {position} {company && <span> at {company}</span>} */}
          {title} <span> at CTS</span>
        </p>
        <p className='my-1'>{location && <span>{location}</span>}</p>
        <Link to={`/profile/${_id}`} className='btn btn-primary'>
          View Profile
        </Link>
      </div>

      {/* <ul>
        {skills.slice(0, 4).map((skill, index) => (
          <li key={index} className='text-primary'>
            <i className='fas fa-check' /> {skill}
          </li>
        ))}
      </ul> */}

      <div>
        <p className='my'>{category && <span>{category}</span>}</p>
        <p className='my '>{type && <span>{type}</span>}</p>
        {dateRange && (
          <p className='my'>
            <span>{dateRange}</span>
          </p>
        )}
        <p className='my'>
          <span> Status:</span>
          <span className={active ? 'text-success p-1' : 'text-danger p-1'}>
            {active ? 'Active' : 'Inactive'}
          </span>
        </p>
      </div>
    </div>
  );
};

ProfileItem.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileItem;
