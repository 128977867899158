import React, { Fragment } from 'react';

const BlogBox = () => (
  <Fragment>
    <div className='box'>
      <h2 className='element'>This is Blog</h2>
    </div>
  </Fragment>
);

export default BlogBox;
