import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { toggleMenu } from "../../actions/togglemenu";
import propsTypes from "prop-types";

const GuestLinks = ({ menuOpen, toggleMenu }) => {
  return (
    <Fragment>
      <ul className={menuOpen ? "open" : ""}>
        <li>
          <NavLink to="/" onClick={toggleMenu}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" onClick={toggleMenu}>
            About
          </NavLink>
        </li>
        <li>
          <NavLink to="/bylaws" onClick={toggleMenu}>
            Bylaws
          </NavLink>
        </li>
        <li>
          <NavLink to="/calendar" onClick={toggleMenu}>
            Calendar
          </NavLink>
        </li>
        <li>
          <NavLink to="/profiles" onClick={toggleMenu}>
            Members
          </NavLink>
        </li>
        <li>
          <NavLink to="/register" onClick={toggleMenu}>
            Register
          </NavLink>
        </li>
        <li>
          <NavLink to="/login" onClick={toggleMenu}>
            Login
          </NavLink>
        </li>
      </ul>
    </Fragment>
  );
};

GuestLinks.propsTypes = {
  toggleMenu: propsTypes.func.isRequired,
  menuOpen: propsTypes.object,
};

const mapStateToProps = (state) => {
  const menuOpen = state.toggleMenu.menuOpen;
  return {
    menuOpen,
  };
};

export default connect(mapStateToProps, { toggleMenu })(GuestLinks);
