import React from "react";
import { MdOutlineSocialDistance } from "react-icons/md";
import { FaEnvelope } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { IoMdContacts } from "react-icons/io";
import ctsLogo from "../../assests/images/ctslogo.jpeg";
import { BsFacebook } from "react-icons/bs";
import { FaSquareXTwitter } from "react-icons/fa6";
import { CiInstagram } from "react-icons/ci";


import styles from "./Footer.module.css";
import "./Footer.css";
import LocalOrganization from "./LocalOrganization";







const Footer = () => {

  // State to manage login/logout
 
 
  return (
    <div className="footer-grid">
      <div className="box1">
        <h2 className={styles.localOrgs}> CommunityConnect</h2>
        <h1 className={styles.connectGroup}>
          <MdOutlineSocialDistance />
        </h1>
        <h4 className={styles.organization}>Organizations </h4>

        <LocalOrganization />
      </div>
      <div className="box2">
        <h2 className={styles.brnadName}>~ Chicagoland Tharu Society ~</h2>
        <img src={ctsLogo} alt="CTS Logo" className={styles.ctsLogo} />
        <h4 className={styles.organization}>Social Media </h4>

        {/* Social Media */}

        <div className={styles.socialMediaContainer}>
          <div className={styles.faceBook}>
            <span className={styles.socialMedia}>
              {" "}
              <a
                href="https://www.facebook.com/profile.php?id=100064450552230&mibextid=LQQJ4d"
                target="_blank"
                className={styles.socialMediaFacebook}
              >
                <BsFacebook />
              </a>
            </span>
          </div>

          {/* end of Facebook */}

          <div className={styles.twitter}>
            <span className={styles.socialMedia}>
              <a
                href="##"
                target="_blank"
                className={styles.socialMediaTwitter}
              >
                <FaSquareXTwitter />
              </a>
            </span>
          </div>

          {/* end of Twitter */}
          <div className={styles.instagram}>
            <span className={styles.socialMedia}>
              <a
                href="##"
                target="_blank"
                className={styles.socialMediaInstagram}
              >
                <CiInstagram />
              </a>
            </span>
          </div>
          {/* end of Instagram */}
        </div>

        
       
          
     
   

        
         
      </div>

      <div className="box3">
        <h2 className={styles.localOrgs}> Contact Us</h2>
        <h1 className={styles.connectGroup}>
          <IoMdContacts />
        </h1>
        <div className={styles.contactInfo}>
          <div className={styles.email}>
            <span className={styles.emailHeading}>
              <FaEnvelope />
            </span>{" "}
            <span className={styles.emailHeading}>Email </span>
          </div>

          <a href="mailto:usatharus@gmail.com" className={styles.email}>
            usatharus@gmail.com
          </a>
        </div>
        {/* end of email */}

        <div className={styles.email}>
          <span className={styles.emailHeading}>
            <FaPhone />
          </span>{" "}
          <span className={styles.emailHeading}>Phone</span>
        </div>

        <p className={styles.phone}>+1 (773) 383-4967</p>
      </div>

     

      <div className="box4">
        <p>Copyright © CTS</p>
      </div>
    </div>
  );
};

export default Footer;
