import React, { Fragment } from 'react';

const FacebookPlugin = () => {
  return (
    <Fragment>
      <div id='facebook-plugin-container'>
        <iframe
          title='myFrame'
          id='facebook-plugin'
          src='https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FChicagoland-Tharu-Society-593661590670622&tabs=timeline&width=350&height=700&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId'
          width='500'
          height='700'
          scrolling='no'
          frameBorder='0'
          allowFullScreen={true}
          allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
        ></iframe>
      </div>
    </Fragment>
  );
};

export default FacebookPlugin;
