import React from "react";
import { MENU_OPEN } from "../actions/types";

const initialState = {
  menuOpen: false,
};

function toggleMenu(state = initialState, action) {
  switch (action.type) {
    case MENU_OPEN:
      return {
        ...state,
        menuOpen: !state.menuOpen,
      };
    default:
      return state;
  }
}

export default toggleMenu;
