import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ProfileTop = ({
  profile: {
    location,
    website,
    social,
    user: { name, avatar },
    membership = [],
  },
}) => {
  const [membershipData, setMembershipData] = useState(null);

  useEffect(() => {
    setMembershipData({
      membership,
    });
  }, [membership]);

  // Destructure the first membership item if available
  const { title } = membershipData?.membership[0] || {};

  return (
    <div className='profile-top bg-primary p-2'>
      <img className='round-img my-1' src={avatar} alt='' />
      <h1 className='large'>{name}</h1>
      <p className='lead'>
        {title} {<span> at CTS</span>}
      </p>
      <p>{location ? <span>{location}</span> : null}</p>
      <div className='icons my-1'>
        {website ? (
          <a href={website} target='_blank' rel='noopener noreferrer'>
            <i className='fas fa-globe fa-2x' />
          </a>
        ) : null}
        {social
          ? Object.entries(social)
              .filter(([_, value]) => value)
              .map(([key, value]) => (
                <a
                  key={key}
                  href={value}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className={`fab fa-${key} fa-2x`}></i>
                </a>
              ))
          : null}
      </div>
    </div>
  );
};

ProfileTop.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileTop;
