import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addMembership } from "../../actions/profile";

import Alert from "../shared/Alert";
import isDateValid from "../../utils/dateValidation";
import { setAlert } from "../../actions/alert";

const AddMembership = ({ addMembership }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    category: "",
    type: "",
    from: "",
    to: "",
    current: false,
    active: false,
  });

  const { title, category, type, from, to, current, active } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    // Validation for required fields
    const requiredFields = ["title", "category", "type", "from"];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    // Check if the to date is greater than the from date
    const isValid = isDateValid(formData.from, formData.to);

    if (formData.current) {
      formData.to = null;
    }

    addMembership(formData).then(() => {
      if (!missingFields.length > 0) {
        if (isValid) {
          navigate("/dashboard");
        } else {
          // Display an error message or handle the validation error
          setAlert("To date must be greater than from data", "danger");
        }
      }
    });
  };

  return (
    <>
      <section className="container">
        <h1 className="large text-primary">Add Membership Info</h1>
        <p className="lead">
          <i className="fas fa-user-plus" /> Add any membership info that you
          have had in the past
        </p>
        <form className="form" onSubmit={onSubmit}>
          <div className="form-group">
            <select name="title" value={title} onChange={onChange}>
              <option value="" disabled>
                * Select your title
              </option>
              <option value="President">President</option>
              <option value="Vice President">Vice President</option>
              <option value="General(Well-Wisher)">General(Well-Wisher)</option>
            </select>
            <small className="form-text">
              Provide your title that you belong in the community
            </small>
          </div>

          <div className="form-group">
            <select name="category" value={category} onChange={onChange}>
              <option value="" disabled>
                * Select your category
              </option>
              <option value="Founder Member">Founder Member </option>
              <option value="Executive Member">Executive Member</option>
              <option value="Advisory Member">Advisory Member</option>
              <option value="General Member">General Member</option>
            </select>
            <small className="form-text">
              Provide your member category that you belong in the community
            </small>
          </div>

          <div className="form-group">
            <select name="type" value={type} onChange={onChange}>
              <option value="" disabled>
                * Select your membership type
              </option>
              <option value="Lifetime Membership">Lifetime Membership </option>
              <option value="General Membership">General Membership</option>
            </select>
            <small className="form-text">
              Provide your memberhip type that you belong in the community
            </small>
          </div>

          <div className="form-group">
            <small className="form-text">From Date</small>
            <input type="date" name="from" value={from} onChange={onChange} />
          </div>
          <div className="form-group">
            <p>
              <input
                type="checkbox"
                name="current"
                checked={current}
                value={current}
                onChange={() => {
                  setFormData({ ...formData, current: !current });
                }}
              />
              <label className="form-label">Current </label>
            </p>
          </div>
          <div className="form-group">
            <small className="form-text">To Date</small>
            <input
              type="date"
              name="to"
              value={to}
              onChange={onChange}
              disabled={current}
            />
          </div>

          {/* <div className='form-group'>
              <p>
                <input
                  type='checkbox'
                  name='active'
                  checked={active}
                  value={active}
                  onChange={() => {
                    setFormData({ ...formData, active: !active });
                  }}
                />
                <label className='form-label'>Active Membership </label>
              </p>
            </div> */}
          <input type="submit" className="btn btn-primary my-1" />
          <Link className="btn btn-secondary my-1" to="/dashboard">
            Go Back
          </Link>
        </form>
        <span>
          <Alert />
        </span>
      </section>
    </>
  );
};

AddMembership.propTypes = {
  addMembership: PropTypes.func.isRequired,
};

export default connect(null, { addMembership })(AddMembership);
