import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import formatDate from '../../utils/formatDate';

const ProfileMembership = ({ membership = [] }) => {
  const [membershipData, setMembershipData] = useState(null);

  useEffect(() => {
    // console.log('membership', {
    //   membership,
    // });

    setMembershipData({
      membership,
    });
  }, [membership]);

  // Destructure the first membership item if available
  const { title, category, type, from, to, active } =
    membershipData?.membership || {};

  return (
    <div>
      <h3 className='text-dark'>{title}</h3>
      <p>
        <strong>Duration: </strong>
        {from && formatDate(from)} - {to ? to && formatDate(to) : 'Current'}
      </p>
      <p>
        <strong>Category: </strong> {category}
      </p>
      <p>
        <strong>Type: </strong> {type}
      </p>

      <p>
        <strong>Status: </strong>
        <span className={active ? 'text-success' : 'text-danger'}>
          {active ? 'Active' : 'Inactive'}
        </span>
      </p>
    </div>
  );
};

ProfileMembership.propTypes = {
  membership: PropTypes.object.isRequired,
};

export default ProfileMembership;
